@font-face {
  font-family: IranSans;
  src: url('./assets/Iranian\ Sans.ttf');
}

h2 {
  font-family: IranSans !important;
}

html {
  background: url('assets/background.png') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow-y: scroll;
  font-family: IranSans;
}

html body {
  margin-right: 0 !important;
}
